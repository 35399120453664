body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Light'), local('TTFirsNeue-Light'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Regular'), local('TTFirsNeue-Regular'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Bold Italic'), local('TTFirsNeue-BoldItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue DemiBold Italic'), local('TTFirsNeue-DemiBoldItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-DemiBoldItalic.ttf) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue ExtraLight'), local('TTFirsNeue-ExtraLight'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Thin Italic'), local('TTFirsNeue-ThinItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-ThinItalic.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Medium Italic'), local('TTFirsNeue-MediumItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-MediumItalic.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue DemiBold'), local('TTFirsNeue-DemiBold'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-DemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Black Italic'), local('TTFirsNeue-BlackItalic'),
        x url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-BlackItalic.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Light Italic'), local('TTFirsNeue-LightItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue ExtraLight Italic'), local('TTFirsNeue-ExtraLightItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-ExtraLightItalic.ttf) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Bold'), local('TTFirsNeue-Bold'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue ExtraBold'), local('TTFirsNeue-ExtraBold'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Medium'), local('TTFirsNeue-Medium'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Thin'), local('TTFirsNeue-Thin'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue ExtraBold Italic'), local('TTFirsNeue-ExtraBoldItalic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-ExtraBoldItalic.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Black'), local('TTFirsNeue-Black'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TT Firs Neue';
    src: local('TT Firs Neue Italic'), local('TTFirsNeue-Italic'),
        url(/public/assets/fonts/ttFirsNeue/TTFirsNeue-Italic.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

body {
    background-color: #ffffff;
    /* font-family: 'TT Firs Neue';
  font-weight: 200; */
}

/* html{
  overflow-y: overlay;
} */

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.rs-picker-menu {
    z-index: 999;
}

a {
    color: inherit;
    text-decoration: none;
}
